<template>
	<div
		class="dnd-list"
		:class="{'has-subcategories': hasSubcategory}"
	>
		<Container
			:data-index="index"
			group-name="column"
			:get-child-payload="itemIndex => getChildPayload(itemIndex)"
			:should-accept-drop="() => true"
			drag-class="card-ghost-drop"
			@drop="onDrop"
		>
			<Draggable
				v-for="child in data"
				:key="child.id"
			>
				<ListItem
					:is-new="child.is_new"
					:id="child.id"
					:link="child.link"
					:title="child.title"
					:tags="child.tags"
					:can-edit="canEdit"
					@deleteItem="deleteItem"
				/>
			</Draggable>
		</Container>
	</div>
</template>

<script>
// import components
import { Container, Draggable } from 'vue-smooth-dnd';
import ListItem                 from '@/components/documents/dashboard-item/item.vue';


export default {
	name: 'DragAndDropList',

	props: ['data', 'index', 'canEdit', 'hasSubcategory'],

	components: { ListItem, Container, Draggable },

	methods:
	{
		getChildPayload(itemIndex)
		{
			return this.data[itemIndex];
		},

		onDrop(event)
		{
			this.$emit('onDrop', event)
		},

		deleteItem(value)
		{
			this.$emit('deleteItem', value);
		},
	},
}
</script>

<style lang="scss" scoped>
	.dnd-list
	{
		.smooth-dnd-container
		{
			padding: 30px;
			display: flex;
			flex-direction: column;
			gap: 15px;
		}

		.smooth-dnd-draggable-wrapper { overflow: visible!important;; }

		&.has-subcategories
		{
			.smooth-dnd-container { padding: 30px 30px 0px 30px; }
		}
	}

	.card-ghost-drop
	{
		padding: 8px 12px;
		background: #2C4170;
		border-radius: 8px;
		color: #FFFFFF;
	}
</style>