<template>
	<div class="documents-dashboard__item-subcategory">
		<div
			class="documents-dashboard__item-subcategory-header-wr"
			:class="{'active': expanded || hasDraggable}"
			@click="toggleExpanded"
			@mouseenter="checkDraggable"
			@mouseleave="hovered = false"
		>
			<div class="documents-dashboard__item-subcategory-header-title">
				{{ data.name }}
			</div>
			<div
				v-if="hovered || showOptions"
				class="documents-dashboard__item-subcategory-header-options-wr"
			>
				<div
					class="documents-dashboard__item-subcategory-header-options-icon"
					:class="{'active': showOptions}"
					@click.stop="showOptions = !showOptions"
				>
					<Icon name="more" size="14" />
				</div>
				<div
					v-if="showOptions"
					v-click-outside="closeOptions"
					class="documents-dashboard__item-subcategory-header-options"
					@click.stop
				>
					<div
						class="documents-dashboard__item-subcategory-header-options-item documents-dashboard__item-subcategory-header-options-item--delete"
						@click.stop="showDeleteContext = true, hovered = false, showOptions = false"
					>
						<Icon name="delete" size="14" />
						<span>
							Удалить
						</span>
					</div>
				</div>
			</div>
			<div
				class="documents-dashboard__item-subcategory-header-arrow"
				:class="{'active': expanded || hasDraggable}"
			>
				<Icon name="arrow-down" size="12" />
			</div>
		</div>
		<Container
			v-if="hasDraggable || expanded"
			:data-index="containerIndex"
			group-name="column"
			:get-child-payload="itemIndex => getSubcategoryChildPayload(itemIndex)"
			:should-accept-drop="() => true"
			drag-class="card-ghost-drop"
			@drop="onSubcategoryDrop(groupIndex, containerIndex, $event)"
		>
			<Draggable
				v-for="subcategory in data.knowledges"
				:key="`${subcategory.id}-${containerIndex}`"
			>
				<ListItem
					:is-new="subcategory.is_new"
					:id="subcategory.id"
					:link="subcategory.link"
					:title="subcategory.title"
					:tags="subcategory.tags"
					:can-edit="userCanEdit"
					@deleteItem="deleteItem(groupIndex, data.id, $event)"
				/>
			</Draggable>
		</Container>

		<DeleteContext v-if="showDeleteContext" @close="closeDeleteContext" @delete="deleteSubcategory(groupIndex, containerIndex)">
			<p>
				Удалить подкатегорию '{{ data.name }}'
			</p>
		</DeleteContext>
	</div>
</template>

<script>
// import components
import { Container, Draggable } from 'vue-smooth-dnd';
import ListItem                 from '@/components/documents/dashboard-item/item.vue';
import Icon                     from '@/components/ui/icon/Icon.vue';
import DeleteContext           	from '@/components/context-menu/repeatable/DeleteContext.vue';


export default {
	name: 'Subcategory',

	props: ['data', 'groupIndex', 'containerIndex', 'userCanEdit'],

	components:
	{
		Container,
		Draggable,
		ListItem,
		Icon,
		DeleteContext,
	},

	data()
	{
		return {
			expanded         : false,
			hasDraggable     : false,
			hovered          : false,
			showOptions      : false,
			showDeleteContext: false,
		};
	},

	methods:
	{
		getSubcategoryChildPayload(itemIndex)
		{
			return this.data.knowledges[itemIndex];
		},

		onSubcategoryDrop(groupIndex, subcategoryIndex, dropResult)
		{
			this.$emit('onSubcategoryDrop', { groupIndex, subcategoryIndex, dropResult })
		},

		deleteItem(groupIndex, subcategoryIndex, event)
		{
			this.$emit('deleteSubcategoryItem', { groupIndex, subcategoryIndex, event })
		},

		deleteSubcategory(groupIndex, subcategoryIndex)
		{
			this.$emit('deleteSubcategory', { groupIndex, subcategoryIndex })
		},

		checkDraggable()
		{
			this.hovered = true;
			if (document.querySelector('.smooth-dnd-no-user-select') !== null)
				this.hasDraggable = true;
		},

		toggleExpanded()
		{
			if (this.hasDraggable)
			{
				this.expanded = false;
				this.hasDraggable = false;
				return;
			}

			this.expanded = !this.expanded;
		},

		closeOptions()
		{
			this.showOptions = false;
		},

		closeDeleteContext()
		{
			this.showDeleteContext = false;
		},
	},
}
</script>

<style lang="scss" scoped>
	.documents-dashboard__item-subcategory
	{
		.smooth-dnd-container
		{
			padding: 15px 0;
			display: flex;
			flex-direction: column;
			gap: 15px;

			.smooth-dnd-draggable-wrapper { overflow: visible!important; }
		}
	}

	.documents-dashboard__item-subcategory-header-wr
	{
		cursor: pointer;
		padding-bottom: 10px;
		border-bottom: 0.5px solid transparent;
		display: flex;
		align-items: center;
		gap: 10px;

		&.active { border-bottom: 0.5px solid #4F5152; }
	}

	.card-ghost-drop
	{
		padding: 8px 12px;
		background: #2C4170;
		border-radius: 8px;
		color: #FFFFFF;
	}

	.documents-dashboard__item-subcategory-header-title
	{
		font-size: 16px;
		font-weight: 400;
		line-height: 19px;
		letter-spacing: 0em;
		text-align: left;
		color: #FFFFFF;
		word-break: break-all;
		max-width: 60%;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 1;
		display: -webkit-box;
		-webkit-box-orient: vertical;
	}

	.documents-dashboard__item-subcategory-header-options-wr { position: relative; }

	.documents-dashboard__item-subcategory-header-options-icon
	{
		height: 14px;
		color: #656565;

		&:hover { color: #FFFFFF }
		&.active { color: #FFFFFF }
	}

	.documents-dashboard__item-subcategory-header-options
	{
		cursor: auto;
		position: absolute;
		z-index: 30;
		width: max-content;
		padding: 20px;
		background: #191919;
		border: 0.7px solid #FFFFFF33;
		border-radius: 10px;
		top: 25px;
		left: -10px;
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.documents-dashboard__item-subcategory-header-arrow
	{
		margin-left: auto;
		height: 14px;
		transition: all .2s ease-in-out;

		&.active { transform: rotate(180deg); }
	}

	.documents-dashboard__item-subcategory-header-options-item
	{
		cursor: pointer;
		display: flex;
		font-weight: 300;
		gap: 12px;
		line-height: 17px;
		font-size: 14px;

		&:hover
		{
			span { color: #FFFFFF; }
			.icon { color: #FFFFFF; }
		}

		&--delete
		{
			span { color: #9E4835; }
			.icon { color: #9E4835; }

			&:hover
			{
				span { color: red; }
				.icon { color: red; }
			}
		}
	}
</style>