<template>
	<div
		class="documents-dashboard__item-knowledge"
		:class="{'is-new': isNew}"
		@click="link && link != 'empty' ? redirectToLink(link) : $router.push({ path: `/documents/${id}` })"
		@mouseenter="hovered = true"
		@mouseleave="hovered = false"
	>
		<div ref="tagsText">
			{{title.substr(0, title.length - 1)}}<span ref="lastChar">{{title.charAt(title.length - 1)}}</span>
		</div>
		<div ref="tagsBox" class="span__after">
			<Icon v-if="link && link != 'empty'" name="link" size="10" class="documents-dashboard__item-knowledge-icon" />
			<template v-if="tags && tags.length">
				<div
					v-for="tag in tags"
					:key="tag.id"
					:style="{'background': tag.color}"
					class="documents-dashboard__item-knowledge-tag"
				/>
			</template>
		</div>
		<div ref="moreDetails" class="documents-dashboard__item-knowledge-options-wr" @click.stop>
			<div
				v-if="canEdit && hovered || showOptions"
				class="documents-dashboard__item-knowledge-options-icon"
				:class="{'active': showOptions }"
				@click.stop="showOptions = !showOptions"
			>
				<Icon name="more" size="14" />
			</div>
			<div
				v-if="showOptions && canEdit"
				v-click-outside="closeOptions"
				class="documents-dashboard__item-knowledge-options"
			>
				<div
					class="documents-dashboard__item-knowledge-options-item"
					@click.stop="$router.push(`/documents/${id}#edit`)"
				>
					<Icon name="edit" size="14" />
					<span class="documents-dashboard__item-knowledge-options-item-text">
						Редактировать
					</span>
				</div>
				<div
					class="documents-dashboard__item-knowledge-options-item documents-dashboard__item-knowledge-options-item--delete"
					@click.stop="showDeleteContext = true, hovered = false, showOptions = false"
				>
					<Icon name="delete" size="14" />
					<span class="documents-dashboard__item-knowledge-options-item-text">
						Удалить
					</span>
				</div>
			</div>
		</div>
		<DeleteContext v-if="showDeleteContext" @close="closeDeleteContext" @delete="deleteItem">
			<p>
				Удалить знание '{{ title }}'
			</p>
		</DeleteContext>
	</div>
</template>

<script>
// import components
import Icon          from '@/components/ui/icon/Icon.vue';
import DeleteContext from '@/components/context-menu/repeatable/DeleteContext.vue';

export default {
	name: 'DocumentDashboardListItem',

	components:
	{
		DeleteContext,
		Icon,
	},

	props:
	{
		id:
		{
			type: Number,
		},
		link:
		{
			type: String,
			default: '',
		},
		title:
		{
			type: String,
			default: '',
		},
		tags:
		{
			type: Array,
			default: () => [],
		},
		isNew:
		{
			type: Boolean,
			default: false,
		},
		canEdit:
		{
			type: Boolean,
			default: false,
		},
	},

	data()
	{
		return {
			showOptions: false,
			hovered: false,
			showDeleteContext: false,
		};
	},

	methods:
	{
		closeOptions()
		{
			this.showOptions = false;
		},

		closeDeleteContext()
		{
			this.showDeleteContext = false;
		},

		deleteItem()
		{
			this.$emit('deleteItem', this.id)
			this.showDeleteContext = false;
		},

		redirectToLink(val)
		{
			if (val.includes('http://') || val.includes('https://'))
				window.location.href = val;
			else
				window.location.href = 'http://' + val;
		},

		replaceTags()
		{
			this.$refs.tagsText.offsetHeight > 20 ? 
				(
					this.$refs.tagsBox.style.top = '-17px',
					this.$refs.moreDetails.style.top = '-23px',
					this.$refs.moreDetails.style.height = '2px',
					this.$refs.tagsBox.style.left = this.$refs.lastChar.getBoundingClientRect().x - this.$refs.tagsText.getBoundingClientRect().x  + 15 + 'px',
					this.$refs.moreDetails.style.left =  this.$refs.lastChar.getBoundingClientRect().x - this.$refs.tagsText.getBoundingClientRect().x  + 15 + 'px'
				) :
				null
		},
	},
	mounted()
	{
		this.$nextTick(()=>{
			this.replaceTags()
		})
	},
}
</script>

<style lang="scss">
	.documents-dashboard__item-knowledge
	{
		cursor: grabbing;
		display: flex;
		align-items: center;
		gap: 5px;
		row-gap: 3px;
		flex-wrap: wrap;

		div, span
		{
			font-size: 14px;
			font-weight: 400;
			line-height: 17px;
			word-break: break-word;
			color: #80BEEA;
			transition: all .2s ease;
		}

		.span__after
		{
			position: relative;
			display: flex;
			flex-wrap: wrap;
			column-gap: 5px;
			row-gap: 5px;
		}

		.documents-dashboard__item-knowledge-icon { margin-left: 8px; }
		.delete-context-wrp { cursor: auto; }

		&:hover span:not(.documents-dashboard__item-knowledge-options-item-text),
		&:hover div:not(.icon)
		{
			color: #F7D547;
		}

		&.is-new
		{
			position: relative;
			&::before
			{
				position: absolute;
				content: "";
				top: 50%;
				transform: translateY(-50%);
				left: -12px;
				width: 6px;
				height: 6px;
				border-radius: 100px;
				background: #F7D547;
			}
		}
	}

	.documents-dashboard__item-knowledge-tag
	{
		width: 14px;
		height: 11px;
		border-radius: 8px;
		background: #2C2C2C;
	}

	.documents-dashboard__item-knowledge-options-wr
	{
		position: relative;
	}

	.documents-dashboard__item-knowledge-options-icon
	{
		cursor: pointer;
		height: 14px;
		color:#656565;
		transition: all .2 ease;

		&:hover,
		&.active { color: #FFFFFF; }
	}

	.documents-dashboard__item-knowledge-options
	{
		cursor: auto;
		position: absolute;
		z-index: 30;
		width: max-content;
		padding: 20px;
		background: #191919;
		border: 0.7px solid #FFFFFF33;
		border-radius: 10px;
		top: 25px;
		left: -10px;
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.documents-dashboard__item-knowledge-options-item
	{
		cursor: pointer;
		display: flex;
		gap: 12px;

		.documents-dashboard__item-knowledge-options-item-text
		{
			font-size: 14px;
			font-weight: 300;
			line-height: 17px;
			color: #FFFFFFCC;
		}

		&:hover
		{
			span { color: #FFFFFF; }
			.icon { color: #FFFFFF; }
		}

		&--delete
		{
			span { color: #9E4835; }
			.icon { color: #9E4835; }

			&:hover
			{
				span { color: red; }
				.icon { color: red; }
			}
		}
	}
</style>